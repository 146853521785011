import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import Login from "./components/login/Login";
import DetailPage from "./pages/detailPage/DetailPage";
import AdminLogin from "./components/adminLogin/AdminLogin";
import AdminHome from "./pages/adminHome/AdminHome";
import AdminExportFile from "./pages/adminExportFile/AdminExportFile";
import AuditorList from "./pages/manageAuditor/AuditorList";
import AddAuditor from "./pages/manageAuditor/AddAuditor";
import EditAuditor from "./pages/manageAuditor/EditAuditor";
import RemarkList from "./pages/manageRemark/RemarkList";
import AddRemark from "./pages/manageRemark/AddRemark";
import EditRemark from "./pages/manageRemark/EditRemark";
import QcPanelLogin from "./components/qcPanelLogin/QcPanelLogin";
import QcPanelHome from "./pages/qcPanelHome/QcPanelHome";
import QcPanelDetails from "./pages/qcPanelDetails.jsx/QcPanelDetails";
import QcList from "./pages/manageQc/QcList";
import AddQc from "./pages/manageQc/AddQc";
import EditQc from "./pages/manageQc/EditQc";
import Main from "./pages/main/Main";
import DCAFVerification from "./pages/DCAFVerification/DCAFVerification";

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        {/* <Route path="/" element={<Main />} /> */}
        <Route path="/home/:name" element={<Home />} />
        <Route path="/image-audit/:name/:simType" element={<DetailPage />} />

        {/* DCAF Verification */}
        <Route
          path="/"
          element={<DCAFVerification />}
        />

        {/* admin */}
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/home" element={<AdminHome />} />
        <Route path="/admin/export-file" element={<AdminExportFile />} />

        {/* manage auditor */}
        <Route path="/admin/manage-auditor" element={<AuditorList />} />
        <Route path="/admin/add-auditor" element={<AddAuditor />} />
        <Route path="/admin/edit-auditor/:id" element={<EditAuditor />} />

        {/* manage remark */}
        <Route path="/admin/manage-remark" element={<RemarkList />} />
        <Route path="/admin/add-remark" element={<AddRemark />} />
        <Route path="/admin/edit-remark/:id" element={<EditRemark />} />

        {/* qc panel  */}
        <Route path="/qc-panel/login" element={<QcPanelLogin />} />
        <Route path="/qc-panel" element={<QcPanelHome />} />
        <Route path="/qc-panel/:id" element={<QcPanelDetails />} />

        {/* manage qc */}
        <Route path="/admin/manage-qc" element={<QcList />} />
        <Route path="/admin/add-qc" element={<AddQc />} />
        <Route path="/admin/edit-qc/:id" element={<EditQc />} />
      </Routes>
    </>
  );
}

export default App;
