import React, { useState, useEffect } from "react";
import axios from "axios"; // Assuming you are using axios for API calls
import Header from "../../components/header/Header";
import { Doughnut, Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./DCAFVerification.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Registering elements and plugins with Chart.js
Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const DCAFVerification = () => {
  // States to manage stages and verification data
  const navigate = useNavigate();
  // const location = useLocation();
  const { name, simType } = useParams();
  const [stage, setStage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(500); // Example total entries
  const [completed, setCompleted] = useState("0");
  const [underProcess, setUnderProcess] = useState("0");
  // const [verifyId, setVerifyId] = useState("N");

  useEffect(() => {
    if (sessionStorage.getItem("info") === null) {
      navigate("/login");
    }
  }, []);

  // const handleCheckboxChange = (e) => {
  //   setVerifyId(e.target.checked ? "Y" : "N");
  // };

  //   const [status, setStatus] = useState(null);

  // Function to fetch verification status from the API
  // const fetchVerificationStatus = () => {
  //   axios
  //     .get(
  //       "https://dynamic.org.in/dcaf_audit/auditor/auto_verification_customers_info"
  //     )
  //     .then((response) => {
  //       const { completed, underProcess, success } = response.data;
  //       setCompleted(completed);
  //       setUnderProcess(underProcess);
  //       if (success) {
  //         setStage(3); // Move to 3rd stage when verification is successful
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching verification status:", error);
  //     });
  // };

  const fetchVerificationStatus = async () => {
    try {
      const response = await axios.post(
        API_BASE_URL + "pdfprocess/auto_verification_customers_info",
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("auto verification customers info", response.data.data);
      if (response.data.status) {
        setStage(response.data.data.stage);
        setTotalEntries(response.data.data.total_customer);
        setCompleted(response.data.data.completed_customer);
        setUnderProcess(response.data.data.pending_customer);
        if (response.data.data.status === "Completed") {
          setStage("3rd Stage"); // Move to 3rd stage when verification is successful
        }
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    fetchVerificationStatus();
  }, []);

  // Function to simulate starting verification
  const startVerification = async () => {
    // setStage("2nd Stage");
    // fetchVerificationStatus();
    try {
      const userDetails = sessionStorage.getItem("info");
      const userInfo = JSON.parse(userDetails);
      const finalData = {
        user_id: userInfo.id,
      };
      const response = await axios.post(
        API_BASE_URL + "pdfprocess/start_auto_verification",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("auto verification customers info", response.data.data);
      if (response.data.status) {
        toast.success(response.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(response.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  // Trigger verification status check in intervals for 2nd stage
  useEffect(() => {
    if (stage === "2nd Stage") {
      const interval = setInterval(fetchVerificationStatus, 5000); // Poll every 5 seconds
      //   setStage(3);
      return () => clearInterval(interval); // Clean up the interval on unmount
    }
  }, [stage]);

  // Data for the chart with data labels
  const data = {
    labels: ["Completed", "Under Process"],
    datasets: [
      {
        label: `${simType} Verification`,
        data: [completed, underProcess],
        backgroundColor: ["#4CAF50", "#FFC107"],
        hoverOffset: 4,
      },
    ],
  };

  // Options for the chart, including data labels to show counts
  const options = {
    plugins: {
      datalabels: {
        color: "#000", // Color of the data labels (white for better contrast)
        font: {
          size: 15, // Increase font size
          weight: "bold",
        },
        formatter: (value) => `${value}`,
        anchor: "center", // Align labels to the center of the segments
        align: "center", // Ensure the labels are displayed inside the segments
      },
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
    // cutout: "70%", // Adjust the cutout size to create a bigger center space
    responsive: true,
    maintainAspectRatio: false, // Disable aspect ratio to control height/width
  };

  // back  navigate page

  const handleBack = () => {
    navigate(-1);
  };

  // export pdf

  // Function to simulate starting verification
  const handleExport = async () => {
    try {
      const response = await axios.post(
        API_BASE_URL + "pdfprocess/export_data",
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("export data", response.data.data);
      if (response.data.status) {
        toast.success(response.data.message);
        // Open the link in a new tab
        window.open(response.data.data, "_blank");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  return (
    <>
      <Header />
      <section className="dcaf-container">
        {stage === "1st Stage" && (
          <div className="stage-box">
            <h2>{simType} Verification</h2>
            <p>
              Total Entries: <span>{totalEntries} (Customers)</span>
            </p>
            {/* {location.pathname !== "/verification/Pune/EKYC" && (
              <div className="checkbox-verify-id">
                <label>
                  <input
                    type="checkbox"
                    onChange={handleCheckboxChange}
                    checked={verifyId === "Y"}
                  />
                  Verify Customer Id proof <br />
                  {verifyId === "Y" && (
                    <span style={{ color: "red" }}>
                      It will take some time for verification to complete
                    </span>
                  )}
                </label>
              </div>
            )} */}
            <button
              disabled={underProcess === "0" && "disabled"}
              className="start-btn"
              onClick={startVerification}
            >
              Start Verification
            </button>
          </div>
        )}

        {stage === "2nd Stage" && (
          <div className="stage-box">
            <h2>{simType} Verification in Progress</h2>
            {data.datasets[0].data.some((val) => val > 0) ? (
              <div className="chart-wrapper">
                <Doughnut data={data} options={options} />
              </div>
            ) : (
              <div className="chart-wrapper">
                <p>No data available</p>
              </div>
            )}
            <div className="details">
              <p>
                Total Entries: <span>{totalEntries}</span>
              </p>
              <p>
                Completed: <span>{completed}</span>
              </p>
              <p>
                Under Process: <span>{underProcess}</span>
              </p>
              <p className="status-message">
                This Process will take some time, you can close this window and
                check status letter
              </p>
            </div>
          </div>
        )}

        {stage === "3rd Stage" && (
          <div className="stage-box">
            <h4 className="stage-success-title">
              {simType} Verification Completed
            </h4>
            <h6>All forms Audit Done</h6>
            <button className="backBtn btn" onClick={handleBack}>
              Back
            </button>
            <button
              style={{ backgroundColor: "gray" }}
              className="backBtn btn ms-3"
              onClick={handleExport}
            >
              Export
            </button>
          </div>
        )}
      </section>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </>
  );
};

export default DCAFVerification;
